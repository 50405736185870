$base-font-size: 16px;
$nav-layer-index: 10;
$main-nav-width: 160px;
$main-gutter-width: 24px;
$default-font-face: "Plus Jakarta Sans", Helvetica, Arial, sans-serif;
// $display-font-face: "Söhne Breit";
$display-font-face: "Bullfight", $default-font-face;
$left-margin: $main-nav-width + $main-gutter-width;
$footer-height: 320px;
$modal-radius: 12px;
$dialogue-radius: 6px;
$button-radius: 6px;

$body-background: #270c2f; //old
$home-background: #270c2f;

$color-electric-pink: #dc008b;
$color-electric-green: #88eab1;
// $color-electric-blue: #00c1ff; //old
$color-electric-blue: #00a8e0;
$color-electric-blue-dark: #179ec9;
$color-electric-purple: #7f00ff;
$color-electric-gold: #f2d74c;

$arena-rose: $color-electric-pink;
$arena-rose-light: #ff76cd;
$arena-rose-lighter: #ffd4ef;
$arena-rose-dark: #bd2a6c;

$arena-cyan: #00a8e0;
$arena-cyan-dark: #179ec9;
$arena-cyan-lightest: #f0fbff;
$arena-cyan-light1: #e4f8ff;

$color-electric-blue-light: #72dcff;
$arena-cyan-light: #72dcff;

// $color-host: #70a2ff;
$color-host: #397efe;
// $color-media: #713ddf;
$color-media: #ff8159;
$color-audio-only: #f97cc6;
$color-fan: $arena-rose-light;
$color-any: #3fd8b9;
$color-owner: linear-gradient(171.76deg, #c61584 -10.29%, #660868 61.44%);
$color-solid-gradient: linear-gradient(-45deg, rgba(#3e038e, 1), rgba(#610171, 1), rgba(#b50883, 1));
$color-solid-gradient70: linear-gradient(-45deg, rgba(#3e038e, 0.8), rgba(#610171, 0.8), rgba(#b50883, 0.7));
$color-solid-gradient80: linear-gradient(-45deg, rgba(#3e038e, 0.8), rgba(#610171, 0.8), rgba(#b50883, 0.8));
$hero-overlay-gradient: linear-gradient(202.53deg, rgba(239, 115, 33, 0.92) 12.03%, rgba(128, 5, 131, 1) 59.33%);
$color-solid-gradient-alt: linear-gradient(-56deg, rgba(#0c1cbd, 1), rgba(#4f035c, 1), rgba(#db019c, 1));
$color-solid-gradient-alt88: linear-gradient(-56deg, rgba(#0c1cbd, 0.88), rgba(#4f035c, 0.88), rgba(#db019c, 0.88));

$color-green-gradient: linear-gradient(90deg, hsla(205, 84%, 36%, 1) 0%, hsla(136, 82%, 72%, 1) 100%);
$color-green-gradient2: linear-gradient(90deg, hsla(136, 48%, 54%, 1) 0%, hsla(58, 99%, 48%, 1) 100%);
$color-green-gradient3: linear-gradient(90deg, hsla(165, 89%, 31%, 1) 0%, hsla(161, 46%, 49%, 1) 100%);

$color-background: $color-solid-gradient; //new

$color-fan-light: #ff76cd;
$color-host-light: #70a2ff;
// $color-media-light: #9f73fd;
$color-media-light: #f59a49;

// $color-media-lighter: #c6b1ff;
$color-host-lighter: #a8c8ff;
$color-fan-lighter: #ffbbf0;
$color-media-lighter: #f9b374;

$color-background-tint-black: rgba(black, 0.32);
$color-background-tint-black-4: rgba(black, 0.04);
$color-background-tint-black-8: rgba(black, 0.08);
$color-background-tint-black-16: rgba(black, 0.16);
$color-background-tint-black-50: rgba(black, 0.5);
$color-background-tint-black-32: rgba(black, 0.32);
$color-background-tint-black-64: rgba(black, 0.64);
$color-background-tint-black-72: rgba(black, 0.72);
$color-background-tint-black-88: rgba(black, 0.88);

$color-background-tint-white: rgba(white, 0.32);
$color-background-tint-white-4: rgba(white, 0.04);
$color-background-tint-white-8: rgba(white, 0.08);
$color-background-tint-white-16: rgba(white, 0.16);
$color-background-tint-white-50: rgba(white, 0.5);
$color-background-tint-white-32: rgba(white, 0.32);
$color-background-tint-white-64: rgba(white, 0.64);
$color-background-tint-white-72: rgba(white, 0.72);

$color-background-tint-white-88: rgba(white, 0.88);
$color-background-tint-white-96: rgba(white, 0.96);

$color-background-tint-purple-50: rgba(#713ddf, 0.5);

//overlay
$color-background-gradient: linear-gradient(
    -45deg,
    rgba(#4810cc, 0.7),
    rgba(#9b10cc, 0.7),
    rgba(#3d0171, 0.7),
    rgba(#b50883, 0.7)
);
//overlay
$color-background-gradient2: linear-gradient(
    -64deg,

    rgba(#9b10cc, 0.96),
    rgba(#4810cc, 0.96),
    rgba(#3d0171, 0.96),
    rgba(#b50883, 0.96)
);
$color-text-gradient: linear-gradient(
    70deg,
    rgba(#c9b2ff, 0.96),
    rgba(#63f949, 0.96),
    rgba(#e06bf7, 0.96),
    rgba(#ffff00, 0.96)
);

// implementing from in progress style guide
$royal-black: #200e18;
$royal-dark: #421b4a;
$royal: #672675;
$new-purple: #320d3d;
$dark-purple: #270c2f;
$dark-purple96: rgba($dark-purple, 96%);
$rose-light: #ff76cd;
$rose-lighter: #ffb4e3;
$orange: #f2994a;
$orange-dark: #f57300;
$yellow: #f2d74c;

$red: #e44545;
$red-light: #f07171;
$red-lighter: #fab8b8;
$red-lightest: #ffd9d9;

// added these from bulma just to have some base colors
$black: hsl(0, 0%, 4%);
$black-bis: hsl(0, 0%, 7%);
$black-ter: hsl(0, 0%, 14%);

$grey-darker: hsl(0, 0%, 21%);
$grey-dark: hsl(0, 0%, 29%);
$grey: hsl(0, 0%, 48%);
$grey-light: hsl(0, 0%, 71%);
$grey-lighter: hsl(0, 0%, 86%);
$grey-lightest: hsl(0, 0%, 93%);

$white-ter: hsl(0, 0%, 96%);
$white-bis: hsl(0, 0%, 98%);
$white: hsl(0, 0%, 100%);

// $orange: hsl(14, 100%, 53%);
// $yellow: hsl(44, 100%, 77%);
$green: hsl(153, 53%, 53%);
$green-dark: #005a26;
$turquoise: hsl(171, 100%, 41%);
$cyan: hsl(207, 61%, 53%);
$blue: hsl(229, 53%, 53%);
$purple: hsl(271, 100%, 71%);
$arena-green: $color-electric-green;
$role-color-fan: $arena-rose-light;
$role-color-host: $color-electric-blue;
$role-color-owner: $color-owner;
$role-color-director: $orange;
$role-color-media: $color-media-lighter;

// forms
$error: #db3799;
$error-on-dark: palevioletred;

$color-hero-gradient: linear-gradient(0deg, $grey-lightest 0%, $grey-lightest 70%, rgba(255, 255, 255, 1) 98%);

$account-input-border-color: $color-background-tint-black-16;

$chatbg-is-owner: linear-gradient(171.76deg, #e70291 -10.29%, #8d0090 61.44%);

$event-page-bg: #1e0073;
$event-gradient-top: linear-gradient(180deg, #1e0073 -39.19%, rgba(8, 69, 89, 0) 100%);
$event-gradient-mid: linear-gradient(360deg, #1e0073 8.33%, rgba(30, 0, 115, 0.54) 66.82%, rgba(30, 0, 115, 0) 89.71%);
$event-gradient-bottom: linear-gradient(180deg, #1e0073 8.33%, rgba(30, 0, 115, 0) 105.16%);

// $event-page-bg: #1e0073;
// $event-gradient-top: linear-gradient(180deg, #1e0073 -39.19%, rgba(8, 69, 89, 0) 100%);
// $event-gradient-mid: linear-gradient(360deg, #1e0073 8.33%, rgba(30, 0, 115, 0.54) 66.82%, rgba(30, 0, 115, 0) 89.71%);
// $event-gradient-bottom: linear-gradient(180deg, #1e0073 8.33%, rgba(30, 0, 115, 0) 105.16%);

$event-page-bg-temp: #1a8cb0;
$event-gradient-top-temp: linear-gradient(180deg, #1a8cb0 -39.19%, rgba(8, 69, 89, 0) 100%);
$event-gradient-mid-temp: linear-gradient(
    360deg,
    #1a8cb0 8.33%,
    rgba(30, 0, 115, 0.54) 66.82%,
    rgba(30, 0, 115, 0) 89.71%
);
$event-gradient-bottom-temp: linear-gradient(
    180deg,
    #1a8cb0 8.33%,
    rgba(26, 140, 176, 0.59) 48.86%,
    rgba(26, 140, 176, 0) 105.16%
);
$top-fade-gradient-eased: linear-gradient(
    to bottom,
    hsla(272.61, 100%, 9.02%, 0.45) 0%,
    hsla(272.61, 100%, 9.02%, 0.421) 10.9%,
    hsla(272.61, 100%, 9.02%, 0.391) 19.8%,
    hsla(272.61, 100%, 9.02%, 0.36) 27.1%,
    hsla(272.61, 100%, 9.02%, 0.327) 33%,
    hsla(272.61, 100%, 9.02%, 0.294) 37.9%,
    hsla(272.61, 100%, 9.02%, 0.261) 42.1%,
    hsla(272.61, 100%, 9.02%, 0.228) 45.9%,
    hsla(272.61, 100%, 9.02%, 0.195) 49.6%,
    hsla(272.61, 100%, 9.02%, 0.163) 53.6%,
    hsla(272.61, 100%, 9.02%, 0.132) 58.1%,
    hsla(272.61, 100%, 9.02%, 0.102) 63.5%,
    hsla(272.61, 100%, 9.02%, 0.073) 70%,
    hsla(272.61, 100%, 9.02%, 0.047) 78.1%,
    hsla(272.61, 100%, 9.02%, 0.022) 88%,
    hsla(272.61, 100%, 9.02%, 0) 100%
);

$black-trans30: rgba(0, 0, 0, 0.3);
$black-trans50: rgba(0, 0, 0, 0.5);
$black-trans80: rgba(0, 0, 0, 0.8);

$player-sidebar-bg-color: $color-background-tint-black-8;

$player-bg-color: linear-gradient(296deg, rgba(#0055a2, 1), rgba(#1e0123, 1), rgba(#b90081, 1)); //  june23
// $player-bg-color: $color-solid-gradient;

$studio-bg-color: linear-gradient(102.41deg, #005572 -7.63%, #1e0047 96.72%); //  june23
$player-border-width: 1px;
$player-border-color: $color-background-tint-white-8;

$theme-hyperx-dark: linear-gradient(
    48deg,
    hsla(205, 46%, 10%, 1) 0%,
    hsla(191, 28%, 23%, 1) 50%,
    hsla(207, 41%, 27%, 1) 100%
);
$theme-hyperx-dusk: linear-gradient(
    48deg,
    hsla(333, 37%, 46%, 1) 0%,
    hsla(243, 10%, 47%, 1) 50%,
    hsla(197, 44%, 42%, 1) 100%
);
$theme-hyperx-dawn: linear-gradient(
    48deg,
    hsla(254, 68%, 9%, 1) 0%,
    hsla(269, 97%, 37%, 1) 33%,
    hsla(320, 77%, 55%, 1) 66%,
    hsla(316, 41%, 93%, 1) 100%
);
$gradient-color-one: #0c1cbd;
$gradient-color-two: #4f035c;
$gradient-color-three: #db019c;

$gradient-alpha-one: 1;
$gradient-alpha-two: 1;
$gradient-alpha-three: 1;

$gradient-angle: -56deg;

$color-arcaneus-red: #ff1561;
