/* variable type test */

@font-face {
    font-family: "Plus Jakarta Sans";
    src: url(../Plus_Jakarta/PlusJakartaSans-VariableFont_wght.ttf) format("truetype");
    font-face: 300 1000;
    font-style: normal;
}

/* @font-face {
    font-family: "Plus Jakarta Sans";
    src: url(../Plus_Jakarta/PlusJakartaSans-Italic-VariableFont_wght.ttf) format("truetype");
    font-face: 300 1000;
    font-style: italic;
} */

/* woff */
/* 
@font-face {
    font-family: "Plus Jakarta Sans";
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url(../Plus_Jakarta/plusjakartasans-italic-webfont.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: "Plus Jakarta Sans";
    font-style: italic;
    font-weight: 500;
    font-display: swap;
    src: url(../Plus_Jakarta/plusjakartasans-mediumitalic-webfont.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: "Plus Jakarta Sans";
    font-style: italic;
    font-weight: 600;
    font-display: swap;
    src: url(../Plus_Jakarta/plusjakartasans-bolditalic-webfont.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: "Plus Jakarta Sans";
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url(../Plus_Jakarta/plusjakartasans-bolditalic-webfont.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: "Plus Jakarta Sans";
    font-style: italic;
    font-weight: 800;
    font-display: swap;
    src: url(../Plus_Jakarta/plusjakartasans-extrabolditalic-webfont.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: "Plus Jakarta Sans";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(../Plus_Jakarta/plusjakartasans-regular-webfont.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: "Plus Jakarta Sans";
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(../Plus_Jakarta/plusjakartasans-medium-webfont.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: "Plus Jakarta Sans";
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(../Plus_Jakarta/plusjakartasans-bold-webfont.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: "Plus Jakarta Sans";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(../Plus_Jakarta/plusjakartasans-bold-webfont.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: "Plus Jakarta Sans";
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url(../Plus_Jakarta/plusjakartasans-extrabold-webfont.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
} */
