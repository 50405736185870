/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on May 13, 2021 */

@font-face {
    font-family: "Bullfight";
    src: url("bullfight-regular.woff2") format("woff2"), url("bullfight-regular.woff") format("woff"), url("bullfight-regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Bullfight";
    src: url("bullfight-bold.woff2") format("woff2"), url("bullfight-bold.woff") format("woff"), url("bullfight-bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "Bullfight";
    src: url("bullfight-black.woff2") format("woff2"), url("bullfight-black.woff") format("woff"), url("bullfight-black.ttf") format("truetype");
    font-weight: 800;
    font-style: normal;
}
