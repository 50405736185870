@font-face {
    font-family: "Futura";
    src:
        local("Futura Regular"),
        local("Futura-Regular"),
        url("FuturaLT.woff2") format("woff2"),
        url("FuturaLT.woff") format("woff"),
        url("FuturaLT.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}
