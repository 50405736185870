form,
label,
input,
select,
.input {
    font-family: $default-font-face;
    width: 100%;
}

.no-select {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.adaptive-wrapper {
    opacity: 0;
    transition: opacity linear 0.25s;
    &.root {
        opacity: 1;
    }
    .adaptive-filler {
        width: 100vw;
        height: 100vh;
        background-color: $color-electric-pink;
    }
}

.draft {
    border: solid 5px $color-electric-pink !important;
    animation: pulse-half 2s infinite;
}

body {
    background-color: black;
    color: white;
    padding: 0;
    margin: 0;
}
