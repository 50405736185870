.page-wrapper {
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    font-family: $default-font-face;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    min-width: 100vw;

    // @include animatedGradientSolid();
    background: linear-gradient(
        $gradient-angle,
        rgba($gradient-color-one, $gradient-alpha-one),
        rgba($gradient-color-two, $gradient-alpha-two),
        rgba($gradient-color-three, $gradient-alpha-three)
    );
    background-size: 200% 200%;
    animation: animated-gradient 6s ease infinite;

    &.modal-open {
        position: fixed;
        max-width: 100vw;
        overflow: hidden;
        #modal,
        #payment-modal,
        #enter-modal {
            width: 100vw;
            height: 100vh;
        }
        // max-height: 100vh;
    }

    .viewport-stats {
        position: fixed;
        z-index: 100;
        display: flex;
        bottom: 0;
        height: px-to-rem(32px);
        width: 100vw;
        color: white;
        align-items: flex-end;
        pointer-events: none;
        user-select: none;
        font-size: px-to-rem(10px);

        .viewport-data {
            display: flex;
            height: px-to-rem(16px);
            width: 100%;
            background: $color-background-tint-black-16;
            background: $color-electric-pink;
            font-weight: bold;
            align-items: center;
            justify-content: space-around;
            overflow: hidden;
            color: $color-background-tint-white-64;
            font-size: px-to-rem(10px);

            span {
                padding: px-to-rem(16px) 0;
            }
        }
    }
}
