$mobile-multiplier: 1.6;
$desktop-multiplier: 1;

.portrait {
    font-size: px-to-rem(calc(16px * $mobile-multiplier));
}

.landscape {
    font-size: px-to-rem(calc(16px * $desktop-multiplier));
}
